import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import ReactTooltip from 'react-tooltip';
import { PlusSmIcon } from '@heroicons/react/solid'
import { InformationCircleIcon } from '@heroicons/react/outline'


import SEO from "../components/common/seo"
import Layout from "../components/common/layout"
import CommunitiesTable from "../components/community/communitiestable"
import TopicList from "../components/topic/topiclist"
import TopicFilters from "../components/topic/filters"
import RelatedTopics from "../components/topic/relatedTopics";
import TopicPlatformIcons from "../components/topic/topicPlatformIcons";
import TopicStats from "../components/topic/stats"
import PlatformCard from "../components/platform/platformcard"
import PromotedTopicCommunities from "../components/community/promotedtopiccommunities"
// import Adsense from "../components/promotions/adsense"
import Deals from "../components/promotions/deals";

import { sortCommunities } from '../utils/communities'


const TopicPage = ({ data, classes, pageContext }) => {
  const topic = data.topic;
  const count = data.communities.totalCount;
  const childTopics = data.childTopics.nodes;
  const relatedTopics = data.relatedTopics.nodes;
  const deals = data.deals.nodes;
  const [tagFilter, setTagFilter] = useState(null);
  const [sizeFilters, setSizeFilters] = useState([]);
  const [featureFilters, setFeatureFilters] = useState([]);
  const sortOptions = ["Popular", "Ratings", "Largest", "Newest"];
  const [sort, setSort] = useState(sortOptions[0]);
  // const datedCommunities = data.communities.nodes.filter(c => c.added)
  // const updateDate = datedCommunities.length ? datedCommunities[datedCommunities.length - 1].added : null;
  
  // communities are sorted by count of members
  const communities = data.communities.nodes;
  const promotedCommunities = communities.filter(c => c.promoted && c.promotedTopics && c.promotedTopics.split(',').includes(topic.slug));

  // // get count of communities by platform
  const platforms = data.allPlatforms.nodes.map(p => {
    p['count'] = communities.filter(c => c.platform === p.slug).length;  // we're overwriting total count for the platform here
    return p;
  }).sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)); 

  // // get count of communities by features
  const features = data.allFeatures.nodes.map(f => {
    f['count'] = communities.filter(c => c[f.slug]).length;  // we're overwriting total count for the feature here
    return f;
  }).sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)); 

  // unique tags, if they exist
  var tags = [];
  communities.forEach((c) => {
    if (c.tags){
      c.tags.split(',').filter(t => topic.allowedTags && topic.allowedTags.split(',').includes(t)).forEach(t => {
        var tagIndex = tags.map(tag => tag.name).indexOf(t);
        if (tagIndex === -1){
          tags.push({'name': t, 'count': 0})
          tagIndex = tags.length - 1
        }
        tags[tagIndex]['count'] += 1
      })
    }
  });
  tags = tags.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)); 

  // sizes of communities
  var sizes = ["Tiny", "Small", "Medium", "Large", "Huge", "Massive", "Gigantic", "Unknown"].map(s => {
    return {
      'name': s,
      'count': communities.filter(c => c.memberSize === s).length
    }
  });

  // make the SEO title
  var title = '';
  if (count > 1){
    title += count
  }
  if (title !== ''){title += ' '}
  title += `Best ${topic.name} communities to join in 2025`

  // const baseUrl = `/topics/${topic.slug}/`

  // filter communiites based on tag filter
  var filteredCommunities = communities;
  if (tagFilter){
    filteredCommunities = filteredCommunities.filter(c => c.tags && c.tags.includes(tagFilter.name))
  }
  if (sizeFilters.length){
    filteredCommunities = filteredCommunities.filter(c => sizeFilters.map(f => f.name).includes(c.memberSize))
  }
  if (featureFilters.length){
    filteredCommunities = filteredCommunities.filter(c => !featureFilters.map(f => c[f.slug]).includes(false))
  }

  // sort them
  filteredCommunities = sortCommunities(sort, filteredCommunities);

  // put promoted communities on the top, then the sorted ones
  filteredCommunities = [
    ...sortCommunities("Ratings", promotedCommunities.map(c => Object.assign(c, {showPromotedTag: true}))),
    ...filteredCommunities.filter(c => promotedCommunities.map(p => p.slug).indexOf(c.slug) === -1),
  ]

  // make SEO description
  var description = `Discover${count ? ' ' + count + ' ' : ' '}${topic.name} communities online`;
  if (filteredCommunities.length > 0){
    description += ', like '
    description += filteredCommunities.slice(0, 3).map(c => c.name).join(', ')
    description += ' and more.'
  } else {
    description += '.'
  }

  // custom promotion here
  var promotedPlatform = null;
  if (topic.slug === 'community-building' && platforms.filter(p => p.promoted).length){
    promotedPlatform = platforms.filter(p => p.promoted)[0];
  }

  // const showPromotionSidebar = !promotedCommunities.length && (promotedPlatform || topic.mentorcruiseUrl || relatedTopics.length);
  const showPromotionSidebar = true;

  const datedCommunities = communities.filter(c => c.updated).sort((a,b) => (a.updated > b.updated) ? 1 : ((b.updated > a.updated) ? -1 : 0)); 
  const createdDate = datedCommunities.length ? (new Date(datedCommunities[0].updated)).toISOString().split('T')[0] : null;
  const updatedDate = datedCommunities.length ? (new Date(datedCommunities[datedCommunities.length - 1].updated)).toISOString().split('T')[0] : null;

  return (
    <Layout pageTitle={`${topic.name} Communities`} noBackground={true} enableShare={true} >
      <SEO title={title} description={description} imageUrl={topic.image} generateImage={true} //generateImageElement={'#communities-list'}
        path={`/topics/${topic.slug}/`}
        schema={
          {
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": `${topic.name} Communities`,
            "datePublished": createdDate,
            "dateModified": updatedDate,
            "description": description,
            "url": `https://thehiveindex.com/topics/${topic.slug}/`,
            "isPartOf": {
              "@type": "WebSite",
              "name": "Hive Index",
              "url": "https://thehiveindex.com/"
            },
            "provider": {
              "@type": "Organization",
              "@id": "Organization",
              "name": "Hive Index"
            },
            "mainEntity": [
              {
                "@type": "DataCatalog",
                "name": `${topic.name} Communities`,
                "description": description,
                "dataset": filteredCommunities.slice(0, 20).map((c) => {
                  return {
                    "@type": "Dataset",
                    "name": c.name,
                    "creator": {
                      "@id": "Organization"
                    },
                    "description": `${c.name} is an online community${c.platform ? ` on ${c.platform}` : ``} for ${c.topics.split(',').join(', ')}. ${c.description}`,
                    "url": `https://thehiveindex.com/communities/${c.slug}/`,
                    "image": c.logo ? {
                        "@type": "ImageObject",
                        "url": c.logo,
                        "contentUrl": c.logo
                    } : null,
                    "isPartOf": `https://thehiveindex.com/topics/${topic.slug}/`,
                    "license": ["https://thehiveindex.com/privacy-policy/"],
                    "includedInDataCatalog": 
                      {
                        "@type": "DataCatalog",
                        "name": `${topic.name} Communities`,
                        "url": `https://thehiveindex.com/topics/${topic.slug}/`,
                      }
                    }
                  }
                )
              }
            ]
          }
        }
      />

      {/* Main 3 column grid */}
      <div className={`grid grid-cols-1 gap-4 items-start ${showPromotionSidebar ? 'lg:grid-cols-4' : 'lg:grid-cols-3'} lg:gap-8`}>
        {/* Left column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-3">

          {/* main panel */}
          <div className="rounded-lg bg-white overflow-hidden shadow p-6">

            {count < 5 ? (
              <div className="mb-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex items-center justify-between">
                    <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                      {`About this list of ${topic.name} communities`}
                    </h2>
                  </div>
                </div>
                

                {topic.description ? (
                  <p className="mt-1 text-gray-500 leading-snug">
                    {topic.description}
                  </p>
                ) : (
                  <p className="mt-1 text-gray-500 leading-snug">
                    This list of online communities is dedicated to discussing {topic.name}.<br/>
                  </p>
                )}
              </div>
            ) : ''}
            
            {/*{childTopics && childTopics.length > 0 ? (
              <div className="mt-4">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex items-center justify-between">
                    <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Related Topics</h2>
                  </div>
                </div>
                <div className="mt-2">
                  <TopicList topics={childTopics} mini={true} />
                </div>
              </div>
            ) : ''}*/}

            {/*{(false && count > 30) || (promotedCommunities.length > 0) ? (
              <div className="mb-6">
                <PromotedTopicCommunities promotedCommunities={promotedCommunities} topic={topic} />
              </div>
            ) : ''}*/}

            <div className="" id="communities-list">
              <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex items-center">
                  <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                    Online {count === 1 ? 'Community' : 'Communities'} for {topic.people || topic.name}
                  </h2>
                  <span className="flex-shrink-0 font-semibold text-xs text-gray-500 bg-white px-1 pr-2">
                    {filteredCommunities.length !== topic.count ? (
                      <span>{filteredCommunities.length} / </span>
                    ) : ''}
                    <span>{topic.count}</span>
                  </span>
                  {updatedDate ? (
                    <div className="hidden sm:block ml-auto text-sm bg-white text-gray-500 pl-3">Updated <b>{updatedDate}</b></div>
                  ) : ''}
                  {/*<Link to="/submit/" className="ml-auto hidden sm:inline-block">
                    <button
                      type="button"
                      className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      <PlusSmIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span>Add</span>
                    </button>
                  </Link>*/}
                </div>
              </div>

              <div className="sm:flex items-center mt-2">
                {(platforms.length > 0) ? (
                  <div className="mt-0 mb-2">
                    <TopicPlatformIcons platforms={platforms} topic={topic} totalCount={count}/>
                  </div>
                ) : ''}

                {count >= 10 ? (
                  <div className="flex sm:block ml-auto">
                    <div className="ml-auto">
                      <TopicFilters
                        topic={topic}
                        platforms={[]} //platforms={platforms} // not filtering on platforms here
                        features={features} featureFilters={featureFilters} setFeatureFilters={setFeatureFilters}
                        tags={tags} tagFilter={tagFilter} setTagFilter={setTagFilter}
                        sizes={sizes} sizeFilters={sizeFilters} setSizeFilters={setSizeFilters}
                        sort={sort} setSort={setSort} sortOptions={sortOptions}
                      />
                    </div>
                  </div>
                ) : ''}
              </div>

              

              {count > 0 ? (
                <div className="mt-1">
                  <CommunitiesTable communities={filteredCommunities} showPlatform={true} linkModal={true} showRankings={true} />
                </div>
              ) : (
                <div className="mt-1">
                  <div style={{padding: '20px 0 0', textAlign: 'center', fontWeight: '500', color: '#95a5a6'}}>
                    There are no online communities on this topic yet. Do you know one? Please <Link to="/submit/">submit it</Link>!
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>

        {/* Right column */}
        {showPromotionSidebar ? (
          <div className="grid grid-cols-1 gap-4">

            {promotedPlatform ? (
              <section aria-labelledby="community-builder-tools">
                <div className="rounded-lg bg-white overflow-hidden shadow p-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex items-center justify-between">
                      <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                        Featured Platform
                      </h2>
                    </div>
                  </div>
                  <ul className={`mt-2`}>
                    <PlatformCard platform={promotedPlatform} hideCount={true} showDescription={true} />
                  </ul>
                </div>
              </section>
            ) : ''}

            {relatedTopics && relatedTopics.length ? (
              <RelatedTopics relatedTopics={relatedTopics} topic={topic} />
            ) : ''}

            {deals && deals.length ? (
              <Deals topic={topic} deals={deals} />
            ) : ''}

            <TopicStats topic={topic} communities={filteredCommunities} />

          </div>
        ) : ''}


      </div>

    </Layout>
  )
}

export const topicPageQuery = graphql`
  query TopicPage($slug: String, $communitySlugs: [String], $relatedTopicSlugs: [String]) {
    site {
      siteMetadata {
        title
      }
    }
    topic: googleSpreadsheetSourceTopics(slug: {eq: $slug}) {
      slug
      name
      isRoot
      parentTopic
      count
      people
      image
      description
      allowedTags
      mentorcruiseUrl
      showAds
      added
      updated
      category
      views
      visitors
    }
    communities: allGoogleSpreadsheetSourceCommunities(filter: {slug: {in: $communitySlugs}, removed: {ne: true}}, sort: {fields: order, order: ASC}) {
      totalCount
      nodes {
        name
        slug
        members
        order
        updated
        countFeatures
        platform
        description
        topics
        logo
        tags
        memberSize
        gold
        promoted
        sponsored
        promotedTopics
        forum
        chat
        apply
        established
        pairing
        events
        perks
        jobs
        newsletter
        paid
        courses
        tools
        token
        reviewCount
        reviewAvg
        reviewsPositive
        views
        visitors
        redirects
        claimed
      }
    }
    deals: allGoogleSpreadsheetSourceDeals(filter: {
      topic: {eq: $slug},
      active: {eq: "TRUE"}
    }) {
      nodes {
        order
        title
        subtitle
        topic
        offer
        logo
        url
      }
    }
    childTopics: allGoogleSpreadsheetSourceTopics(filter: {parentTopic: {eq: $slug}}) {
      nodes {
        slug
        name
        isRoot
        count
        people
        image
        description
      }
    }
    relatedTopics: allGoogleSpreadsheetSourceTopics(filter: {slug: {in: $relatedTopicSlugs}}) {
      nodes {
        slug
        name
        isRoot
        count
        people
        image
        description
        visitors
        views
      }
    }
    allPlatforms: allGoogleSpreadsheetSourcePlatforms(sort: {fields: name, order: ASC}) {
      nodes {
        slug
        name
        count
        logoBig
        logoSmall
        groupName
        groupNamePlural
        promoted
        descriptionForCommunities
      }
    }
    allFeatures: allGoogleSpreadsheetSourceFeatures(sort: {fields: name, order: ASC}) {
      nodes {
        slug
        name
        icon
      }
    }
  }
`

export default TopicPage

